<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item">
          <a-select
              class="input"
              v-model="queryParams.authFlag"
              allowClear
              placeholder="认证状态"
          >
            <a-select-option
                v-for="item in authFlagList"
                :value="item.id"
                :key="item.id"
            >{{ item.authFlagName }}
            </a-select-option
            >
          </a-select>
        </div>
        <div class="one-item" style="padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.company"
              placeholder="公司名称"
              allowClear
          />
        </div>
        <div class="one-item" style="padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.company"
              placeholder="公司名称"
              allowClear
          />
        </div>
        <!-- <div class="one-item" style="padding-left: 10px">
          <a-input
            class="input"
            v-model="queryParams.owner"
            placeholder=""
            allowClear
          />
        </div> -->
        <div class="one-item" style="padding-left: 10px">
          <a-input
              class="input"
              v-model="queryParams.mobile"
              placeholder="手机号码"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="uid"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="bizCreditImg" slot-scope="text, record">
        <div v-if="text.bizCreditImg" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(text.bizCreditImg || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(text.bizCreditImg || '').split(',')"
          />
        </div>
      </template>
      <template slot="passStatus" slot-scope="text, record">
        <a-tag :color="record.passFlag | dictName('authPassStatus', 'color')">{{
            record.passFlag | dictName("authPassStatus")
          }}
        </a-tag>
      </template>
      <template slot="content" slot-scope="text, record">
        <div style="max-height:200px;overflow:auto">
          <template v-for="prop in Object.keys(record.contentJson)">
            <div v-if="prop.indexOf('Img')<0 && prop.indexOf('verifyCode')<0" :key="prop">
              <b>{{ prop }}：</b>{{ record.contentJson[prop] }}
            </div>
          </template>
        </div>
      </template>

      <template slot="bizCreditImg" slot-scope="text, record">
        <div v-if="record.contentJson.bizCreditImg" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.contentJson.bizCreditImg || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.contentJson.bizCreditImg || '').split(',')"
          />
        </div>
        <div v-else style="color: #cdcdcd">无图片信息</div>
      </template>
      <template slot="bizHeadImg" slot-scope="text, record">
        <div v-if="record.contentJson.bizHeadImg" class="img-list">
          <el-image
              style="width: 40px; height: 40px"
              :src="(record.contentJson.bizHeadImg || '') | fullImgPath"
              fit="contain"
              :preview-src-list="(record.contentJson.bizHeadImg || '').split(',')"
          />
        </div>
        <div v-else style="color: #cdcdcd">无图片信息</div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-dropdown>
          <a-menu slot="overlay">
            <a-menu-item
                key="auth"
                :style="{ color: textColor }"
                @click="handleAction($event, record)"
            >
              <a-icon type="check"></a-icon>
              审核
            </a-menu-item>
<!--            <a-menu-divider/>-->
<!--            <a-menu-item-->
<!--                key="setting"-->
<!--                :style="{ color: textColor }"-->
<!--                @click="handleAction($event, record)"-->
<!--            >-->
<!--              <a-icon type="setting"/>-->
<!--              状态配置-->
<!--              &lt;!&ndash; {{ record.helpAuth == 1 ? "取消协助权限" : "允许协助权限" }} &ndash;&gt;-->
<!--            </a-menu-item>-->
          </a-menu>
          <a-button>
            操作
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </template>
    </a-table>
    <user-modal ref="userModal" @success="editSuccess"/>
    <setting-modal ref="settingModal" @success="editSuccess"/>
  </a-card>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import userModal from "./companyAuditModal.vue";
import settingModal from "./settingModal.vue";
import Template from "../../diy/template";

export default {
  name: "CompanyAuthList",
  mixins: [ListMixin],
  components: {Template, userModal, settingModal},
  data() {
    return {
      listGetUrl: "auth/authList",
      authFlagList: [
        {
          id: "0",
          authFlagName: "新建",
        },
        {
          id: "1",
          authFlagName: "待审核",
        },
        {
          id: "2",
          authFlagName: "已认证",
        },
      ],
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "用户ID",
          align: "center",
          width: 100,
          dataIndex: "applyUid"
        },
        {
          title: "用户名",
          width: 150,
          align: "center",
          dataIndex: "username",
        },
        {
          title: "认证状态",
          width: 100,
          align: "center",
          scopedSlots: {customRender: "passStatus"},
        },
        {
          title: "待审核信息",
          align: "left",
          dataIndex: "content",
          scopedSlots: {customRender: "content"},
        },

        {
          title: "营业执照",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "bizCreditImg"},
        },
        {
          title: "公司照片(门头照)",
          align: "center",
          width: 100,
          scopedSlots: {customRender: "bizHeadImg"},
        },
        {
          title: "创建时间",
          width: 105,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "审核人",
          width: 105,
          align: "center",
          dataIndex: "authPerson",
        },
        {
          title: "审核时间",
          width: 105,
          align: "center",
          dataIndex: "authTime",
        },
        {
          title: "审核说明",
          width: 105,
          align: "center",
          dataIndex: "authRemark",
        },
        {
          title: "操作",
          align: "center",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: {customRender: "operation"},
        },
      ];
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    afterFetch(data) {
      data.records.forEach(v => {
        try {
          let contentJson = JSON.parse(v.content)

          v.contentJson = contentJson || {}
        } catch (e) {
          v.contentJson = {}
        }
      })
    },
    editSuccess(operate) {
      this.fetch();
      if (!operate) {
        this.$message.success("状态配置成功");
        return;
      }
      if (operate == 1) {
        this.$message.success("审核认证成功");
      } else {
        this.$message.error("审核认证不成功");
      }
    },
    handleAction(e, row) {
      switch (e.key) {
        case "auth":
          if (row.authFlag == 2) {
            this.$message.success("该用户已经认证完成，无需再审核");
            return;
          }
          // if (row.authFlag == 0) {
          //   this.$message.success("该用户资料未完善，不支持审核");
          //   return;
          // }
          this.$refs.userModal.showModal(row,'company');
          break;
        case "setting":
          this.$refs.settingModal.showModal(row);
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
